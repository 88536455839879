$primary: #FDDFE2;
$dark-pink: #FFC7DB;
$red-stroke: #B32121;
$gray-text: #00000080;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  font-family: 'Montserrat', sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.clickable {
  cursor: pointer;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.hide-scrollbar {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

input::placeholder {
  font-size: 14px;
}

@media (max-width: 577px) {
  .header-container .col-12 {
    padding-right: 0;
    padding-left: 0;
  }
}

.header {
  background-color: $primary;
  padding: 50px 30px 30px 30px;

  .search-bar {
    display: flex;

    input {
      flex: 1;
      margin-right: 10px;
      border-radius: 5px;
      border-width: 0;
      padding-left: 20px;

      &:focus {
        outline: none;
      }
    }

    button {
      background-color: white;
      border-color: white;
      color: black;
      position: relative;

      &:active {
        background-color: $dark-pink;
        border-color: $dark-pink;
      }
    }

    .cart-count {
      background-color: $red-stroke;
      color: white;
      border-radius: 50%;
      width: 15px;
      height: 15px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 10px;
      position: absolute;
      top: 3px;
      right: 3px;
    }
  }
}

.mobile-header {
  background-color: $primary;
  padding: 20px;
  display: flex;
  align-items: center;
  margin-bottom: 15px;

  p {
    flex: 1;
    margin-bottom: 0;
    text-align: center;
    margin-right: 20px;
  }
}

.title {
  font-weight: bold;
  border-bottom: 3px solid $dark-pink;
  margin: 30px 0;
  width: fit-content;
}

.product-card {
  width: 100%;
  margin-bottom: 20px;

  .image img {
    aspect-ratio: 1;
    width: 100%;
    margin-bottom: 5px;
    border-radius: 10px;
    object-fit: cover;
  }

  .product-title p {
    font-size: 16px;
    margin-bottom: 0;
  }

  .price {
    display: flex;
    align-items: center;
  }

  .original-price {
    text-decoration: line-through;
    text-decoration-color: $red-stroke;
    margin: 0;
    margin-right: 10px;
    font-size: 14px;
  }

  .sale-price {
    margin: 0;
    color: black;
    font-weight: bold;
    font-size: 16px;
  }

  .price-and-action {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .add-to-cart-icon svg {
    color: $dark-pink;

    &:active {
      opacity: 0.5;
    }
  }
}

.cart-product {
  display: flex;
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #dedede;
  
  .image img {
    width: 100px;
    height: 100px;
    border-radius: 5px;
    margin-right: 10px;
    object-fit: cover;
  }

  .content {
    display: flex;
    flex: 1;
    align-items: center;
  }

  .product-info {
    flex: 1;
    align-items: center;
    padding-right: 10px;

    p {
      margin: 0;

      &.price {
        font-weight: bold;
      }
    }
  }

  .actions {
    display: flex;
    align-items: center;
    flex-direction: row;

    @media (max-width: 577px) {
      flex-direction: column-reverse;
    }

    svg::active {
      opacity: 0.5;
    }

    .qty {
      width: 40px;
      height: 40px;
      margin: 0;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

.checkout-form {
  input:focus, textarea:focus {
    outline: none !important;
    box-shadow: none !important;
    border-color: $primary;
  }
}

@media (max-width: 577px) {
  .cart-products, .checkout-form {
    overflow-y: scroll;
    overflow-x: hidden;
    height: calc(100dvh - 187px);
  }
}

.footer {
  padding-bottom: 20px;

  @media (min-width: 576px) {
    width: 400px;
    margin-left: auto;
  }

  .total-amount {
    padding-top: 10px;
    display: flex;
    font-weight: bold;
    justify-content: space-between;
  }

  .btn-checkout {
    width: 100%;
    border-radius: 15px;
    background-color: black;
    border-color: black;

    &:active {
      background-color: black;
      border-color: black;
    }
  }
}

.summary-modal {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-top: 40px;
  padding-bottom: 40px;
  
  h2 {
    font-weight: bold;
    margin-top: 10px;
    margin-bottom: 20px;
  }

  .order-number {
    padding-bottom: 10px;
    font-weight: bold;
    border-bottom: 1px solid gray;
    margin-bottom: 20px;
    font-size: 18px;
  }

  .order-success-alert {
    border-radius: 20px;
    width: 90%;
    padding: 10px;
  }

  .order-summary {
    background-color: $primary;
    border-radius: 20px;
    padding: 20px;
    width: 90%;
  }

  .summary-row {
    display: flex;
    
    .summary-icon {
      width: 30px;
    }

    .summary-label {
      font-weight: bold;
    }

    .summary {
      color: $gray-text
    }
  }

  button.btn-back-home {
    width: 90%;
    border-radius: 15px;
    background-color: black;
    border-color: black;

    &:active {
      background-color: black;
      border-color: black;
    }
  }
}

.product-detail {
  word-wrap: break-word;
  overflow-wrap: break-word;

  -webkit-hyphens: auto;
    -moz-hyphens: auto;
          hyphens: auto;

  .image-carousel {
    width: 100%;

    img {
      width: 100%;
      aspect-ratio: 1;
      margin-bottom: 5px;
      border-radius: 10px;
      object-fit: cover;
    }
  }
}

.product-detail-mobile {
  .headers.bg-primary {
    background-color: $primary !important;
  }

  .headers {
    width: 100%;
    z-index: 10000;
    top: 0;
    position: absolute;
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: fixed;
  
    button, button:active {
      background-color: white;
      border-color: white;
      position: relative;
    }

    .cart-count {
      background-color: $red-stroke;
      color: white;
      border-radius: 50%;
      width: 15px;
      height: 15px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 10px;
      position: absolute;
      top: 3px;
      right: 3px;
    }
  }

  .image-carousel {
    width: 100%;

    img {
      width: 100%;
      aspect-ratio: 1;
      margin-bottom: 5px;
      object-fit: cover;
    }
  }

  .carousel-indicators {
    bottom: 25px;

    button {
      width: 7px;
      height: 7px;
      border-radius: 5px;
      border: 0;
    }
  }

  .product-content {
    margin-top: -30px;
    z-index: 1000;
    position: relative;
    background: white;
    border-radius: 20px;
    padding: 20px;
  }

  .product-info-mobile {
    height: calc(100dvh - 80px);
    overflow-y: scroll;
  }

  .product-actions {
    padding: 0 20px;

    .btn-add-to-cart {
      margin-top: 20px;
      margin-bottom: 20px;
    }
  }
}

.product-detail, .product-detail-mobile {
  h4 {
    margin-bottom: 20px;
  }

  .product-original-price {
    text-decoration: line-through;
    text-decoration-color: $red-stroke;
  }

  .product-sale-price {
    color: black;
    font-weight: bold;
    font-size: 20px;
    margin-bottom: 20px;
  }

  h6 {
    margin-bottom: 10px;
    font-weight: bold;
    font-size: 18px;
  }

  .btn-add-to-cart {
    width: 100%;
    border-radius: 15px;
    background-color: black;
    border-color: black;
    margin-top: 30px;
    margin-bottom: 30px;

    &:active {
      background-color: black;
      border-color: black;
    }
  }
}

.no-product-found {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  font-weight: bold;
  flex-direction: column;

  .image {
    background-color: $primary;
    width: 150px;
    height: 150px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
    margin-top: 50px;
  }
}

.small-text {
  font-size: 14px;
}